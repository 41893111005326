/* eslint-disable */
<template>
	<div>
		<div class="timeline__post__actions">
			<!-- Like -->
			<button
				:class="{'active' : likedByUser}"
				class="icon-button timeline__icon timeline__thumb"
				@click="likePost">
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="size-6">
					<path stroke-linecap="round" stroke-linejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z" />
				</svg>
			</button>
			
			<!-- Comment -->
			<button
				v-if="post.comment_status === 'open'"
				class="icon-button timeline__icon timeline__comment-action"
				@click.prevent="viewingComments = !viewingComments"
			>
				<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M5.09927 17.5076L0.370117 21.223V1.58451C0.370117 1.30297 0.481957 1.03297 0.681035 0.833889C0.880112 0.634812 1.15012 0.522972 1.43166 0.522972H20.5393C20.8209 0.522972 21.0909 0.634812 21.29 0.833889C21.489 1.03297 21.6009 1.30297 21.6009 1.58451V16.446C21.6009 16.7276 21.489 16.9976 21.29 17.1967C21.0909 17.3957 20.8209 17.5076 20.5393 17.5076H5.09927ZM4.36469 15.3845H19.4778V2.64605H2.49319V16.8547L4.36469 15.3845ZM9.92396 7.95374H12.047V10.0768H9.92396V7.95374ZM5.67781 7.95374H7.80089V10.0768H5.67781V7.95374ZM14.1701 7.95374H16.2932V10.0768H14.1701V7.95374Z" fill="currentColor"/>
				</svg>

				<span v-if="isAdmin && pendingCount > 0" class="timeline__post__actions__pending">( {{pendingCount}} Pending)</span>
			</button>

			<!--  Bookmark -->
			<button
				:class="{'active' : isBookmarkedByUser}"
				class="icon-button timeline__icon timeline__bookmark-action"
				@click="bookmarkPost">
				<Transition name="fade">
					<span class="icon-button__action-message" v-show="justBookmarked">
						{{ isBookmarkedByUser ? 'Bookmarked' : 'Removed from bookmarks' }}
					</span>
				</Transition>
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="size-6">
					  <path stroke-linecap="round" stroke-linejoin="round" d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0Z" />
				</svg>
				<i></i>
				
			</button>

			<span
				v-if="isAuthor && postStatus === 'pending'"
				class="timeline__post__review">(Only visible to you)</span>

			<div class="timeline__likedby" >
				<div class="timeline__likedby__inner"  v-if="post.liked_by && post.liked_by.length > 0">
					
					<div @click.prevent="showLikesAction">Liked by {{latestLikedBy}} <span v-if="post.liked_by.length > 1"> and {{(post.liked_by.length - 1)}} other{{(post.liked_by.length > 2) ? 's' : null}}</span></div>

					<Transition name="fade">
						<div class="timeline__likedby__list" v-if="showLikes">
								<a :href="'/profile/'+liker.ID" v-for="(liker,id) in post.liked_by" :key="'liked-by-'+id" class="timeline__likedby__list__user">
										<user-badge :rank="liker.rank.tier_name" :colour="liker.rank.tier_colour" :avatar="liker.avatar"></user-badge> {{liker.display_name}}
								</a>

								<button class="button timeline__likedby__list__bottom-back" @click="closeLikes">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
										<path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
									</svg>
									Back to Timeline
								</button> 
						</div>
					</Transition>
				</div>
			</div>

			<div v-if="commenterAvatars.length > 0 && post.comment_status === 'open'" @click.prevent="viewingComments = !viewingComments">
				<div class="timeline__commentors">
					<div v-for="avatar in commenterAvatars" :key="avatar" class="timeline__commentors__user">
						<img :src="avatar" class="timeline__commentors__avatar size-6" />
					</div>

					<button class="timeline__commentors__responses" >{{commentCount}} responses</button>
				</div>
			</div>
		</div>

		<Comments
			v-if="viewingComments && post.comment_status === 'open'"
			:comments="coalescedComments"
			:user="user"
			:post-id="post.ID"
			@commentsUpdated="commentsUpdated"
			@commentApproved="commentsApproved"
			@commentRejected="commentsRejected"
		/>


	</div>
</template>

<script>
import Comments from '@/components/TimelineComponents/CommentsComponent';
import UserBadge from '@/components/UserBadgeComponent';
import {mapMutations} from 'vuex';

export default {
	components: {
		Comments,
		UserBadge
	},

	props: {
		post: {
			type: Object,
			required: true,
		},
		user: {
			type: Object,
			required: true,
		},
	},

	data () {
		return {
			likeCounter: 0,
			likedByUser: false,
			commentCount : 0,
			rejectedCount : 0,
			comments: null,
			showLikes: false,
			viewingComments: false,
			justBookmarked: false,
		};
	},

	mounted() {
		this.likeCounter = this.post.likes;
		this.likedByUser = this.post.liked_by_user;
		this.commentCount = this.post.comment_counter.approved;

		this.$root.$on('navScrollTop', () => {
			this.showLikes = false;
		});

		window.addEventListener("hashchange", (event) => {
			if(event.oldURL.includes('#likes')) {
				this.closeLikes();
			}
		});
	},

	computed: {
		isBookmarkedByUser() {
			if (this.user.bookmarks) {
				return this.user.bookmarks.includes(this.post.ID);
			}

			return false;
		},

		isAdmin () {
			if (this.user.role === 'editor' || this.user.role === 'administrator') {
				return true;
			}

			return false;
		},

		latestLikedBy() {
			if(this.post.liked_by && this.post.liked_by.length > 0){
				let latestUser = this.post.liked_by[this.post.liked_by.length-1];
				
				if(latestUser.ID == this.user.id && this.post.liked_by.length > 1) {
					return this.post.liked_by[this.post.liked_by.length-2].display_name;
				}

				if(latestUser.ID == this.user.id) {
					return 'You';
				}

				return	this.post.liked_by[this.post.liked_by.length-1].display_name
			}
		},

		isAuthor () {
			if (this.user.id === this.post.post_author) {
				return true;
			}

			return false;
		},

		coalescedComments () {
			return this.comments || this.post.comments;
		},

		commenterAvatars () {
			if (this.coalescedComments) {
				// comment.avatar is sometimes empty
				return Array.from(new Set(this.coalescedComments.map(comment => comment.avatar).filter(avatar => avatar))).slice(0, 5);
			}
		},

		pendingCount() {
			return this.post.comment_counter.pending - this.rejectedCount;
		}
	},

	methods: {
		showLikesAction() {
			this.showLikes = true;
			history.pushState(true, '', '#likes')
		},

		closeLikes() {
			this.showLikes = false;
			history.replaceState(null, null, ' ');
		},

		likePost () {
			if (this.likedByUser) {
				this.likeCounter = this.likeCounter - 1;
				this.likedByUser = false;
				gtag('event', 'click', {'event_category': 'Timeline', 'event_label': 'Unliked' + this.post.post_title});

				if(this.latestLikedBy == 'You') {
					this.post.liked_by = [];
				} else {
					let index = this.post.liked_by.findIndex( (post) => {
						return post.ID == this.user.id;	
					});

					if (index > -1) {
						this.post.liked_by.splice(index, 1);
					}

				}

			} else {
				this.likeCounter = this.likeCounter + 1;
				this.likedByUser = true;

				if(!this.post.liked_by) {
					this.post.liked_by = [];
				}
				this.post.liked_by.push({ID: this.user.id, display_name: this.user.name, rank: this.user.rank.tier_name});

				gtag('event', 'click', {'event_category': 'Timeline', 'event_label': 'Liked' + this.post.post_title});
			}

			window.axios.put('wp-json/kxi/v1/timeline/like/' + this.post.ID +'?type='+this.post.post_type);
		},

		bookmarkPost() {
			if(this.isBookmarkedByUser) {
				this.$emit('postUnbookmarked', this.post.ID);
			} else {
				this.$emit('postBookmarked', this.post.ID);
			}

			this.justBookmarked = true;
			setTimeout(() => {
				this.justBookmarked = false;
			}, 2000);

		},

		commentsUpdated (newComment, allComments) {
			// If comment is approved
			if (newComment.comment_approved) {
				this.commentCount = parseInt(this.commentCount) + 1;
			}

			this.comments = allComments;
		},

		commentsApproved() {
			this.commentCount = parseInt(this.commentCount) + 1;
			this.rejectedCount = this.rejectedCount + 1;
		},

		commentsRejected () {
			this.rejectedCount = this.rejectedCount + 1;
		},
	},
};
</script>