<template>
	<div>
		<Transition name="fade">
			<div class="sidebar-overlay" v-if="menuOpen" @click.prevent="$root.$emit('openMenu');"></div>
		</Transition>

		<div :class="['sidebar', { 'sidebar-open': menuOpen }]">

		<!-- Close button -->

		<button @click.prevent="$root.$emit('openMenu');" class="icon-button sidebar__close">
			<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
			<path stroke-linecap="round" stroke-linejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
		</svg>

		</button>

		<div class="sidebar-header">
			<router-link
			:to="{ name: 'usersprofile', params: { id: user.id }}">
				<img :src="user.profile_avatar" alt="Profile" class="profile-picture user-avatar" :style="'border-color:' + user.rank.tier_colour"  v-if="user && user.rank && user.rank.tier_colour"/>
			</router-link>
			<div class="user-info">
				<h3>{{ user.name }}</h3>
				<p>User  #{{ user.id }}</p>
			</div>
		</div>
		<nav class="nav-links">
			<router-link class="nav-item" :to="{name: 'home'}">
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 nav-item__icon">
					<path stroke-linecap="round" stroke-linejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
				</svg>

				<span>Feed</span>
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class="size-6 nav-item__chevron">
					<path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
				</svg>
			</router-link>

			<router-link class="nav-item" :to="{path: '/handbooks'}">
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"  class="size-6 nav-item__icon">
					<path stroke-linecap="round" stroke-linejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
					</svg>

				<span>Handbooks</span>
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class="size-6 nav-item__chevron">
					<path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
				</svg>
			</router-link>

			<router-link class="nav-item" :to="{path: '/insights'}">
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 nav-item__icon">
					<path stroke-linecap="round" stroke-linejoin="round" d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
					</svg>


				<span>Insights</span>
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class="size-6 nav-item__chevron">
					<path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
				</svg>
			</router-link>

			<router-link class="nav-item" :to="{path: '/bookmarks'}">
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 nav-item__icon">
  						<path stroke-linecap="round" stroke-linejoin="round" d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0Z" />
					</svg>

				<span>Favourites</span>
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class="size-6 nav-item__chevron">
					<path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
				</svg>
			</router-link>



			<router-link
			:to="{ name: 'usersprofile', params: { id: user.id }}"  class="nav-item">
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 nav-item__icon">
					<path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
				</svg>

				<span>Your Profile</span>
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class="size-6 nav-item__chevron">
					<path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
				</svg>
			</router-link>

			<router-link
			:to="{name: 'profile'}" class="nav-item">
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 nav-item__icon">
					<path stroke-linecap="round" stroke-linejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z" />
					<path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
				</svg>



				<span>Settings</span>
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class="size-6 nav-item__chevron">
					<path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
				</svg>
			</router-link>
		</nav>

	</div>
</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
	props: {
		menuOpen: {
			type: Boolean,
			default: false,
			required: false,
		},
	},
	data() {
		return {
			navLinks: [
				{ icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
</svg>
`, name: 'Feed' },
				{ icon: 'icon-dashboard', name: 'Handbook' },
				{ icon: 'icon-settings', name: 'Settings' },
				// Add more links as needed
			],
		};
	},
	methods: {
		toggleSidebar() {
			this.menuOpen = !this.menuOpen;
		},
	},

	computed: {
		...mapState([
			'user',
		]),
	},

};
</script>

<style scoped lang="scss">
.sidebar {
	position: fixed;
	top: 0;
	left: 0;
	width: 290px;
	height: 100%;
	background-color: #fff;
	color: white;
	transform: translateX(-100%);
	transition: transform 0.3s ease;
	z-index: 90;

	&__close {
		position: fixed;
		right: 10px;
		top: 10px;
		color: white;
	}
}

.sidebar-open {
	transform: translateX(0);
}

.sidebar-header {
	padding: 20px;
	background-color: #000;
}

.profile-picture {
	width: 60px;
	height: 60px;
	border-radius: 50%;
	margin-bottom: 10px;
	border-width: 2px;
	border-style: solid;
}

.user-info {
	display: flex;
	flex-direction: column;
}

.user-info h3 {
	margin: 0;
	font-size: 1.4rem;
	margin-bottom: 5px;
}

.user-info p {
	margin: 0;
	font-size: 1.1rem;
}

.nav-links {
	padding: 20px;
	color: #000;
}

.nav-item {
	display: flex;
	text-decoration: none;
	align-items: center;
	padding: 20px 0px;
	cursor: pointer;
	transition: background 0.3s ease;
	font-size: 1.2rem;
	font-weight: bold;
	border-bottom: 2px solid #000;
	color: #000 !important;

	&__chevron {
		margin-left: auto;
	}

	&__icon {
		margin-right: 15px;
	}
}

.toggle-button {
	position: absolute;
	top: 20px;
	right: -40px;
	width: 40px;
	height: 40px;
	background-color: #2980b9;
	border: none;
	border-radius: 50%;
	color: white;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	outline: none;
}

.toggle-button i {
	font-size: 18px;
}

.sidebar-overlay {
	position: fixed;
	overflow: hidden;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.5);
	z-index: 90;

}
</style>